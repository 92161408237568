<template>
    <div class="content-box">
        <to-history prop-message="添加/编辑订单"></to-history> 
        <div class="content">
        <div>
            <h4 v-if="!flag" class="global_head_title">添加/编辑订单</h4>
            <!-- <div class="content_row">
                <label class="info" for="">订单编号<span class="redS">*</span></label>
                <div class="content_right">
                    <a-input v-model="osn" size="large" placeholder="请输入订单编号" />
                </div>
            </div> -->
            <div class="content_row">
                <label class="info" for="">选择客户 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-select size="large" ref="sel" v-model="client2" placeholder="请选择要添加的客户" :open="false"
                        @focus="chooseClient">
                        <!-- <a-select-option v-for="(item,index) in clientList" :key="index" :value="item.tmjcpb_corp__ids">
                            {{ item.tmjcpb_corp__name }}
                        </a-select-option> -->
                    </a-select>
                </div>
            </div>
            <client :clientFlag="clientFlag" :clientMode="clientMode" @updateClientFlag="updateClientFlag"></client>
            <!-- <div class="content_row">
                <label class="info" for="">订单类型</label>
                <div class="content_right">
                    <a-radio-group name="radioGroup" v-model="type">
                        <a-radio :value="1">
                            商品选择
                        </a-radio>
                        <a-radio :value="2">
                            自定义
                        </a-radio>
                    </a-radio-group>
                </div>
            </div> -->
            <!-- <div class="content_row">
                <label class="info" for="">报价方案</label>
                <div class="content_right">
                    <a :href="url + '/scrm/index/Download?id=' + quotation" v-if="quotation">{{ quotationText }}</a>

                    <label class="fileLabel upFile" for="quotation" style="margin:0 10px;"><a-icon type="upload" /> 上传
                    </label>
                    <input type="file" id="quotation" value="" @change="quoUpload($event)">
                    <a :href="url + '/scrm/index/Download?id=' + quotation">
                        <a-button type="primary" v-if="quotation != 0"><a-icon type="download" />下载</a-button>
                    </a>
                </div>
            </div> -->
            <div class="content_row">
                <label class="info" for="">合同</label>
                <div class="content_right">
                    <a :href="url + '/scrm/index/Download?id=' + contract" v-if="contract">{{ contractText }}</a>
                    <label class="fileLabel upFile" for="contract" style="margin:0 10px;"><a-icon type="upload" /> 上传
                    </label>
                    <input type="file" id="contract" value="" @change="conUpload($event)">
                    <a :href="url + '/scrm/index/Download?id=' + contract">
                        <a-button type="primary" v-if="contract != 0"><a-icon type="download" />下载</a-button>
                    </a>
                </div>
            </div>

            <div class="content_row" v-for="(item, index) in orderField" :key="index">
                <template v-if="item.iswrite == 1">
                    <label class="info" for="">{{ item.text }}</label>
                    <!-- 数字 -->
                    <div class="content_right" v-if="item.type == 'number'">
                        <a-input type="number" size="large" v-model="item.value"></a-input>
                    </div>
                    <!-- 单行文本 -->
                    <div class="content_right" v-if="item.type == 'SingleText' && item.name != 'tmjcpb_corp__name'">
                        <a-input v-model="item.value" size="large"></a-input>
                    </div>

                    <!-- 多行文本 -->
                    <div class="content_right" v-else-if="item.type == 'multiText'">
                        <a-textarea v-model="item.value">{{ item.value }}</a-textarea>
                    </div>
                    <!-- 单选标签 -->
                    <div class="content_right" v-else-if="item.type == 'radioTag'">
                        <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                        <!-- <a-radio-group v-model="item.value">
                            <a-radio class="radioTag" v-for="(option,index2) in item.options" :key="index2" :value="option.value">
                                {{option.value}}
                            </a-radio>
                        </a-radio-group> -->
                        <a-radio-group v-model="item.value" button-style="solid" size="large">
                            <a-radio-button class="radioTag" v-for="(option, index2) in item.options" :key="index2"
                                :value="option.value">
                                {{ option.value }}
                            </a-radio-button>
                        </a-radio-group>
                    </div>
                    <!-- 多选标签 -->
                    <div class="content_right" v-else-if="item.type == 'checkTag'">
                        <!-- <span v-for="(option,index2) in item.options" :key="index2" class="radioTag" :class="[(option.value == item.value)? '' : '']">{{option.value}}</span>  -->
                        <a-checkbox-group v-model="item.value" :options="item.options" size="large"></a-checkbox-group>
                    </div>
                    <!-- 时间 
                    <div class="content_right" v-else-if="item.type == 'time'">
                        <a-time-picker v-model="item.value" /> 
                    </div>-->
                    <!-- 日期 -->
                    <div class="content_right" v-else-if="item.type == 'date'">
                        <a-date-picker :inputReadOnly="true" v-model="item.value" size="large" />
                    </div>
                    <!-- 日期时间 -->
                    <div class="content_right" v-else-if="item.type == 'dateTime'">
                        <a-date-picker :inputReadOnly="true" show-time v-model="item.value" size="large" />
                    </div>
                    <!-- 下拉框 -->
                    <div class="content_right" v-else-if="item.type == 'select'">
                        <a-select size="large" v-model="item.value"
                            @change="item.name == 'tmjcpb_orders__zd1719107059' ? typeChange(item.value) : ''">
                            <a-select-option v-for="(option, index2) in item.options" :key="index2"
                                :value="option.value">{{ option.value }}</a-select-option>
                        </a-select>
                    </div>
                    <!-- 下拉框 多选-->
                    <div class="content_right" v-else-if="item.type == 'selects'">
                        <a-select size="large" mode="multiple" v-model="item.value">
                            <a-select-option v-for="(option, index2) in item.options" :key="index2"
                                :value="option.value">{{ option.value }}</a-select-option>
                        </a-select>
                    </div>
                    <!-- 附件-->
                    <div class="content_right" v-else-if="item.type == 'annex'">
                        <a :href="url + '/scrm/index/Download?id=' + item.value" v-if="item.value">{{ item.value2 }}</a>
                        <label class="fileLabel upFile" for="annex"><a-icon type="upload" /> 上传 </label>
                        <input type="file" id="annex" value="" @change="annexUpload($event, index)">
                    </div>
                    <!-- 单张图 -->
                    <div class="content_right" v-else-if="item.type == 'image'">
                        <label v-if="item.value" class="fileLabel" for="image"><img
                                :src="url + '/scrm/index/getFile?id=' + item.value" style="margin-bottom:10px"></label>
                        <label v-else class="fileLabel" for="image"><img src="../../../assets/imgs/add2.png"
                                alt=""></label>
                        <input type="file" id="image" value="" @change="imgUpload($event, index)">
                    </div>
                    <!-- 多张图-->
                    <div class="content_right" v-else-if="item.type == 'images'">
                        <img v-if="item.value" :src="url + '/scrm/index/getFile?id=' + item.value">
                        <label v-else class="fileLabel" for="image"><img src="../../../assets/imgs/add2.png"
                                alt=""></label>
                        <input type="file" id="image" value="" @change="imgUpload($event, index)">
                    </div>
                    <!-- 地区-->
                    <div class="content_right" v-else-if="item.type == 'city'">
                        <a-cascader size="large" v-model="item.value" />
                    </div>
                </template>
            </div>
            <div class="content_row">
                <label class="info" for="">订单金额 </label>
                <div class="content_right">
                    <a-input disabled v-model="money" size="large" placeholder="请输入金额" prefix="￥" />
                </div>
            </div>
            <div class="content_row" v-if="id == null || id == ''">
                <label class="info" for="">预收款金额</label>
                <div class="content_right">
                    <a-input v-model="amounting" size="large" placeholder="请输入金额" prefix="￥" />
                </div>
            </div>
            <div class="content_row" v-if="id == null || id == ''">
                <label class="info" for="">收款方式</label>
                <div class="content_right">
                    <a-radio-group name="radioGroup" v-model="method">
                        <a-radio v-for="(item, index) in methodArr" :value="index + 1" :key="index">
                            {{ item }}
                        </a-radio>
                    </a-radio-group>
                </div>
            </div>
            <div class="content_row">
                <label class="info" for="">收款时间</label>
                <div class="content_right">
                    <a-date-picker v-model="receipt_time" size="large" />
                </div>
            </div>
            <div class="content_row" v-if="id == null || id == ''">
                <label class="info" for="">收款备注</label>
                <div class="content_right">
                    <a-textarea :auto-size="{ minRows: 5, maxRows: 8 }" v-model="doc"
                        placeholder="请输入收款备注"></a-textarea>
                </div>
            </div>
            <!-- <div class="content_row">
                <label class="info" for="">订单期限 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-range-picker v-model="date" size="large" format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" />
                </div>
            </div> -->
            <div class="content_row">
                <label class="info" for="">签约时间<span class="redS">*</span></label>
                <div class="content_right">
                    <a-date-picker v-model="start" :inputReadOnly="true" size="large" />
                </div>
            </div>
            <!-- <div class="content_row">
                <label class="info" for="">订单结束时间 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-date-picker v-model="end" :inputReadOnly="true" size="large" />
                </div>
            </div> -->
            <!-- <div class="content_row">
                <label class="info" for="">选择跟进人 <span class="redS">*</span></label>
                <div class="content_right">
                    <a-select size="large" ref="sel2" v-model="staff_uid2" placeholder="请选择跟进人" :open="false"
                        @focus="chooseStaff">
                    </a-select>
                </div>
            </div> -->
            <!-- 选择员工 -->
            <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>

            <div class="save"><a-button type="primary" @click="submit">保存</a-button></div>
        </div>
    </div>

    </div>
    
</template>

<script>
import { requestXml, isMobile, getDateTime, fileUpload, ajaxUrl } from '../../../assets/js/request';
import Client from '../../../components/client.vue';
import Staff from '../../../components/staff.vue';
var moment = require('moment');
export default {
    components: {
        Client, Staff
    },
    data() {
        return {
            flag: true,
            clientMode: 0,
            url: ajaxUrl,
            // 客户
            // clientList: [],
            client: "",
            client2: "",
            clientFlag: false,
            // 类型
            type: 2,
            // 订单编号
            osn: "",
            // 报价方案
            quotation: 0,
            // 订单
            contract: 0,
            quotationText: "",
            contractText: "",
            // 日期
            // sign_time: "",
            // date: [],
            start: "",
            end: "",
            // 金额
            money: null,
            // 预收款金额
            amounting: null,
            method: 1,
            methodArr: ["公户打款", "微信", "支付宝", "刷卡", "现金"],
            // 收款时间
            receipt_time: "",
            // 收款备注
            doc: "",

            staffFlag: false,
            staff_uid: "",
            staff_uid2: "",

            // 订单id
            id: "",
            // 状态
            // status: null,
            // orderStatus: ["取消","新建","进行中","已完成"],
            orderField: [],
            detail: "",
            order_money: {}
        };
    },
    mounted: function () {
        this.clientMode = 1;
        this.getfield();
        this.getorder_money()

        if (isMobile()) {
            this.flag = true;
        } else {
            this.flag = false;
        }
        if (this.$route.query.id) {
            this.id = this.$route.query.id;
            this.getOrderDetail();
        }
        // else{
        //     this.getOsn();
        // }
        // 客户详情页添加时跳转过来的
        this.detail = this.$route.query.detail;

        if (this.$route.query.client) {
            this.client = Number(this.$route.query.client);
            this.client2 = this.$route.query.clientName;
        }
    },

    methods: {
        // 获取订单自定义字段
        getorder_money() {
            requestXml("/scrm/order/getOrderMoney", "GET", (res) => {
                console.log('order_money', res)
                this.order_money = res;
            })
        },
        typeChange(value) {
            if (value == '进件订单') {
                this.money = this.order_money.jj_order_money
            }
            if (value == '培训订单') {
                this.money = this.order_money.px_order_money
            }
        },
        // 选择客户
        chooseClient() {
            this.clientFlag = true;
            this.$refs.sel.blur();
        },
        updateClientFlag(data) {
            for (let i = 0; i < data.length; i++) {
                this.client = data[i].tmjcpb_corp__ids;
                this.client2 = data[i].tmjcpb_corp__name;
            }
            this.clientFlag = false;
        },
        // 选择跟进人
        chooseStaff() {
            this.staffFlag = true;
            this.$refs.sel2.blur();
        },
        updateStaffFlag(data) {
            console.log(data, "dasds")
            if (data != 1) {
                this.staff_uid = data.userid;
                this.staff_uid2 = data.name;
            }
            this.staffFlag = false;
        },
        // 获取订单编号
        // getOsn(){
        //     requestXml("/scrm/order/getOSN","GET",(res) => {
        //         this.osn = res.osn;
        //     })
        // },
        // 报价方案
        quoUpload(e) {
            fileUpload(e, (res) => {
                console.log(res)
                this.quotation = res.id;
                this.quotationText = res.name;
            })
        },
        // 订单
        conUpload(e) {
            fileUpload(e, (res) => {
                this.contract = res.id;
                this.contractText = res.name;
            })
        },
        // 获取客户
        // getClientList() {
        //     requestXml("/scrm/Corp/getUnBindCorp","GET",(res) => {
        //         this.clientList = res;
        //     })
        // },
        // 获取订单自定义字段
        getfield() {
            requestXml("/scrm/order/getfield", "GET", (res) => {
                console.log(res)
                this.orderField = res;
            })
        },

        // 获取订单详情
        getOrderDetail() {
            requestXml("/scrm/Order/getDetails", "GET", (res) => {
                console.log(res, "resres")
                this.start = new moment(getDateTime(res.sign_time));
                this.end = new moment(getDateTime(res.end_time));
                // this.date[0] = this.start;
                // this.date[1] = this.end;
                this.osn = res.osn;
                this.client = Number(res.ids);
                this.client2 = res.corp_name;
                this.quotationText = res.quotation_name;
                this.contractText = res.contract_name;
                this.quotation = res.quotation;
                this.contract = res.contract;
                this.sign_time = new moment(getDateTime(res.sign_time));
                this.money = res.amount;
                this.staff_uid = res.staff_uid;
                this.staff_uid2 = res.staff_name;

                for (let key in res.orders) {
                    for (let i = 0; i < this.orderField.length; i++) {
                        if (key == this.orderField[i].name) {
                            this.orderField[i].value = res.orders[key];
                        }
                    }
                }
            }, { id: this.id })
        },

        // 附件
        annexUpload(e, index) {
            fileUpload(e, (res) => {
                this.orderField[index].value = res.id;
                this.orderField[index].value2 = res.name;
            })
        },
        // 单张图
        imgUpload(e, index) {
            fileUpload(e, (res) => {
                this.orderField[index].value = res.id;
            })
        },
        // 添加订单
        submit() {
            // if (!this.osn) {
            //     this.$message.warning('请输入订单编号');
            //     return false;
            // }
            if (!this.client) {
                this.$message.warning('请选择要添加的客户');
                return false;
            }
            if (!this.money) {
                this.$message.warning('请选择订单类型');
                return false;
            }
            if(this.receipt_time){
                this.receipt_time=this.receipt_time._d.getTime()
            }else{
                this.receipt_time=''
            }
            // // 添加时判断这两个值
            // if(this.id == ""){
            //     if(!this.amounting){
            //         this.$message.warning('请输入预收款金额');
            //         return false;
            //     }
            //     if(!this.method){
            //         this.$message.warning('请选择付款方式');
            //         return false;
            //     }
            // }
            if (!this.start) {
                this.$message.warning('请选择签约时间');
                return false;
            }
            // if (!this.end) {
            //     this.$message.warning('请选择订单结束时间');
            //     return false;
            // }
            // if (!this.staff_uid) {
            //     this.$message.warning('请选择跟进人');
            //     return false;
            // }
            // 添加 更新
            var url;
            if (this.id) {
                url = "/scrm/Order/up"
            } else {
                url = "/scrm/Order/add"
            }


            let order = this.orderField;
            let orders = {};
            for (let i = 0; i < order.length; i++) {
                if (order[i].value && order[i].iswrite == 1) {
                    if (order[i].type == 'date' || order[i].type == 'dateTime' || order[i].type == 'time') {
                        order[i].value = order[i].value._d;
                    }
                    if (order[i].type == 'selects' && order[i].value.length != 0) {
                        order[i].value = order[i].value.join(",");
                    }
                    if (order[i].type == 'checkTag' && order[i].value.length != 0) {
                        order[i].value = order[i].value.join(",");
                    }
                    orders[order[i].name] = order[i].value;
                }
            }
            let orderData = {
                "id": this.id,
                "ids": this.client,
                "osn": this.osn,
                "quotation": this.quotation,
                "contract": this.contract,
                "amount": this.money,
                "amounting": this.amounting,
                "method": this.method,
                "receipt_time": this.receipt_time,
                "doc": this.doc,
                "sign_time": this.start._d.getTime(),
                "orders": orders,
                "staff_uid": this.staff_uid
            }
            requestXml(url, "POST", (res) => {
                if (res == "success") {
                    // 手机还是pc
                    if (this.detail == "opc") {
                        this.$router.push({
                            path: "/scrm_pc/order/orderList",
                        });
                    } else if (this.detail == "owap") {
                        this.$router.go(-1);
                    } else {
                        if (isMobile()) {
                            this.$router.push({
                                path: "/scrm_wap/order/orderList",
                            });
                        } else {
                            this.$router.push({
                                path: "/scrm_pc/order/orderList",
                            });
                        }
                    }
                }
            }, orderData)
        }
    }
};
</script>
<style scoped>

.content-box {
    background-image: url('../../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
}
    .content-box{
        height: 100vh;
        background-color:#F5F5F5;
        padding: 15px;
    }
    .content{
        padding: 20px;
        border-radius: 30px;
    background: #fff;
    margin-bottom: 80px;
    }
.content_right img{
    width: 120px;
}
.content-box {
        position:relative;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: left .3s ease-in-out;
        overflow-y: auto;
    }
.content .content_row {
    align-items: center;
    margin: 3rem 0;
}

.content_right {
    width:100%;
    text-align: left;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #36A3FF;
    border-color: #36A3FF;
}

.content .content_row .info {
    display: block;
    width: 100%;
    text-align: left;
    padding-right: 1rem;
        line-height: 2.5;
    color: #202020;
    font-weight: normal;
}

.content .content_row .fileLabel {
    line-height: 1.499;
    font-weight: 400;
    background-image: none;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    height: 32px;
    line-height: 32px;
    padding: 5px 15px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
}

.content .content_row input[type="file"] {
    display: none;
}

/* 输入框 下拉框 */
.content .content_row .ant-select {
    width: 100%;
    background-color: #F5F5F5;
    border: none;
}
.content .content_row .ant-select-selection--multiple input {
    width: 0;
}

/* 日期 */
.content .content_row .ant-calendar-picker {
    width: 100% !important;
}

.content .content_row .ant-calendar-picker input {
    width: 100%;
}

.save {
    position: fixed;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 60px;
    background: #fff;
    padding: 10px 0 ;
}

.save .ant-btn {
    width: 80%;
    height: 40px;
    background: linear-gradient(87deg, #4371CA 0%, #6F98E2 100%);
border-radius: 40px;
}

@media screen and (min-width: 750px) {
    .content-box {
        position: absolute;
        left: 240px;
        right: 0;
        top: 60px;
        bottom: 0;
        transition: left .3s ease-in-out;
        height: calc(100% - 60px);
        overflow-y: scroll;
    }
    img{
        max-width: 300px;
    }
    .content {
        width: calc(100vw - 300px);
        margin: 20px;
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px rgba(235, 236, 238, 0.8);
    }

    .content .content_row {
        margin: 15px 0;
        font-size: 16px;
    }

    .content .content_row .info {
        width: 200px;
        padding-right: 10px;
    }

    .content .content_row .ant-calendar-picker {
        width: 400px !important;
    }

    .content .content_row .ant-calendar-picker input {
        width: 100% !important;
    }

    /* input ,selsct */
    .content .content_row .ant-input,
    .content .content_row .ant-input-affix-wrapper,
    .content .content_row .ant-select {
        width: 400px;
    }

    .save .ant-btn {
        margin-left: 105px;
    }

}
</style>
